var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kanban-page-container" },
    [
      !_vm.isWidget
        ? _c(
            "PriorityNavigation",
            {
              ref: "kanban-grid-toolbar",
              staticClass: "kanban grid-toolbar border toolbar-bottom-gap",
              attrs: {
                closeDropdown: _vm.closePriorityNavDropdown,
                forceRefresh: _vm.forcePriorityNavRefresh,
              },
              on: {
                "update:closeDropdown": function ($event) {
                  _vm.closePriorityNavDropdown = $event
                },
                "update:close-dropdown": function ($event) {
                  _vm.closePriorityNavDropdown = $event
                },
                "update:forceRefresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
                "update:force-refresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
              },
            },
            [
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                    _vm.filterMouseEnterEvent,
                    _vm.onFilterOver,
                  ]),
                },
                [
                  !_vm.filterPinned
                    ? _c(
                        "b-dropdown",
                        {
                          ref: "filter",
                          staticClass:
                            "action-bar-dropdown text-filter first-item",
                          attrs: {
                            id: `BTN_FILTER_${_vm.id}`,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          on: { hide: _vm.onFilterTextDropdownHide },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      class:
                                        _vm.filterText !== "" ? "active" : "",
                                      attrs: {
                                        icon: ["far", "file-magnifying-glass"],
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3499512239
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass: "filter-padding",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "search-input" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t("task.filter"),
                                    },
                                    on: {
                                      focus: function ($event) {
                                        _vm.filterTextFocus = true
                                      },
                                      blur: function ($event) {
                                        _vm.filterTextFocus = false
                                      },
                                      keypress: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.onFilterSubmit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.filterText,
                                      callback: function ($$v) {
                                        _vm.filterText = $$v
                                      },
                                      expression: "filterText",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: {
                                            id: `BTN_CLEAR_FILTER_${_vm.id}`,
                                            size: "sm",
                                          },
                                          on: { click: _vm.onFilterClear },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "xmark"] },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_CLEAR_FILTER_${_vm.id}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("button.clear")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: {
                                            id: `BTN_SEARCH_FILTER_${_vm.id}`,
                                            size: "sm",
                                          },
                                          on: { click: _vm.onFilterSubmit },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "magnifying-glass"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_SEARCH_FILTER_${_vm.id}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("button.search")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: {
                                            id: `BTN_PIN_FILTER_${_vm.id}`,
                                            size: "sm",
                                          },
                                          on: { click: _vm.onPinFilter },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "thumbtack"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_PIN_FILTER_${_vm.id}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("button.pinOnToolbar")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "li",
                [
                  _vm.filterPinned
                    ? _c(
                        "b-input-group",
                        { staticClass: "search-input first-item" },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: _vm.$t("task.filter") },
                            on: {
                              focus: function ($event) {
                                _vm.filterTextFocus = true
                              },
                              blur: function ($event) {
                                _vm.filterTextFocus = false
                              },
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onFilterSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: `BTN_CLEAR_FILTER_${_vm.id}`,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onFilterClear },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "xmark"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `BTN_CLEAR_FILTER_${_vm.id}`,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.clear")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: `BTN_SEARCH_FILTER_${_vm.id}`,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onFilterSubmit },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "magnifying-glass"],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `BTN_SEARCH_FILTER_${_vm.id}`,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.search")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: `BTN_UNPIN_FILTER_${_vm.id}`,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onUnPinFilter },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "thumbtack"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `BTN_UNPIN_FILTER_${_vm.id}`,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.unpinFromToolbar")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: `BTN_ADV_SEARCH_${_vm.id}` } },
                    [
                      _c(
                        "b-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showProjectSettings()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            class:
                              _vm.filter === null ||
                              _vm.filter.query === null ||
                              (_vm.filter.query !== null &&
                                _vm.filter.query.children.length === 0)
                                ? ""
                                : "active",
                            attrs: { icon: ["far", "filter"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_ADV_SEARCH_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("project.settings.filter")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: `BTN_CLEAR_ADV_SEARCH_${_vm.id}` } },
                    [
                      _c(
                        "b-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clearProjectSettings(true)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { color: "var(--grid-toolbar-button)" },
                            attrs: { icon: ["far", "filter-circle-xmark"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_CLEAR_ADV_SEARCH_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("project.settings.clear")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.configDidLoad && _vm.canEdit("PROJECT")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_KANBAN_SETTINGS_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showKanbanSettings()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "gear"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_KANBAN_SETTINGS_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("kanban.settings.board")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                {
                  staticClass: "view",
                  on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                    _vm.viewMouseEnterEvent,
                    _vm.onViewOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "view",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        id: `BTN_VIEW_${_vm.id}`,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "desktop"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4186387599
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "#" }, on: { click: _vm.savePreset } },
                        [
                          _c("span", { staticClass: "action-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("task.button.save"))),
                          ]),
                        ]
                      ),
                      _c("b-dropdown-divider"),
                      _vm._l(_vm.kanbanViews, function (item, index) {
                        return [
                          _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              staticClass: "action-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.loadViewSettings(item)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "action-item-label-with-icon",
                                  attrs: { id: `viewname${index}` },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm.isEllipsisActive(item.name)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `viewname${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      "custom-class": "popover-margin",
                                      triggers: "hover",
                                      offset: "-100",
                                      content: item.name,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                [
                                  !_vm.editPermission(item)
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "action-item-icon",
                                            attrs: {
                                              id: `COPY_COLUMN_${index}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.copyColumnSettings(
                                                  item.name,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "copy"] },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: `COPY_COLUMN_${index}`,
                                              triggers: "hover",
                                              placement: "top",
                                              boundary: "viewport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("task.button.copy")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "action-item-icon position-third",
                                            attrs: { id: `BTN_INFO_${index}` },
                                            on: _vm._d(
                                              {
                                                mouseleave: function ($event) {
                                                  return _vm.onInfoLeave(index)
                                                },
                                              },
                                              [
                                                _vm.infoMouseEnterEvent,
                                                function ($event) {
                                                  return _vm.onInfoOver(index)
                                                },
                                              ]
                                            ),
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "circle-info"],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("b-popover", {
                                          ref: `BTN_INFO_${index}`,
                                          refInFor: true,
                                          attrs: {
                                            target: `BTN_INFO_${index}`,
                                            show: _vm.showInfo[index],
                                            placement: "top",
                                            boundary: "viewport",
                                            triggers: "manual",
                                            content: _vm.$t("owner", [
                                              _vm.views[index].owner,
                                            ]),
                                          },
                                          on: {
                                            "update:show": function ($event) {
                                              return _vm.$set(
                                                _vm.showInfo,
                                                index,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm.editPermission(item)
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "action-item-icon position-third",
                                            attrs: {
                                              id: `SHARE_COLUMN_${index}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.shareColumnSettings(
                                                  index,
                                                  item.name,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: [
                                                  item.defaultView
                                                    ? "fas"
                                                    : "far",
                                                  "share-nodes",
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: `SHARE_COLUMN_${index}`,
                                              triggers: "hover",
                                              placement: "top",
                                              boundary: "viewport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("task.button.share")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.editPermission(item)
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "action-item-icon position-second",
                                            attrs: {
                                              id: `UPDATE_COLUMN_${index}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.updateColumnSettings(
                                                  index,
                                                  item.name,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "floppy-disk"],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: `UPDATE_COLUMN_${index}`,
                                              triggers: "hover",
                                              placement: "top",
                                              boundary: "viewport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("task.button.update")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.editPermission(item)
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "action-item-icon",
                                            attrs: {
                                              id: `REMOVE_COLUMN_${index}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.removeColumnSettings(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "trash-can"],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: `REMOVE_COLUMN_${index}`,
                                              triggers: "hover",
                                              placement: "top",
                                              boundary: "viewport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("task.button.delete")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                    _vm.colorMouseEnterEvent,
                    _vm.onColoringOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "coloring",
                      staticClass: "coloring-btn action-bar-dropdown",
                      attrs: {
                        id: `BTN_COLORING_${_vm.id}`,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "palette"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1244906976
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-group",
                        { attrs: { header: _vm.$t("colorby") } },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "none",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("none"))),
                              ]),
                              _vm.coloring.none
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "column",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.column"))),
                              ]),
                              _vm.coloring.column
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "task",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.task"))),
                              ]),
                              _vm.coloring.task
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "stage",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.stage"))),
                              ]),
                              _vm.coloring.stage
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  _vm.coloring.company = !_vm.coloring.company
                                  _vm.onColorChange("skill", "kanban_coloring")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.skill"))),
                              ]),
                              _vm.coloring.skill
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "staff",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.staff"))),
                              ]),
                              _vm.coloring.staff
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "resource",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("task.coloring.resource"))
                                ),
                              ]),
                              _vm.coloring.resource
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "rebate",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.rebate"))),
                              ]),
                              _vm.coloring.rebate
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.onColorChange(
                                    "file",
                                    "kanban_coloring"
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("task.coloring.file"))),
                              ]),
                              _vm.coloring.file
                                ? _c("font-awesome-icon", {
                                    staticClass: "active-check",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.configDidLoad && _vm.canEdit("PROJECT")
                ? _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onDisplaySettingsLeave }, [
                        _vm.displaySettingsMouseEnterEvent,
                        _vm.onDisplaySettingsOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "kanban-display-settings",
                          staticClass: "display-settings action-bar-dropdown",
                          attrs: {
                            id: `BTN_DISPLAY_SETTINGS_${_vm.id}`,
                            "no-caret": "",
                            "toggle-class": "text-decoration-none",
                          },
                        },
                        [
                          _c("template", { slot: "button-content" }, [
                            _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "list-check"] },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "b-dropdown-group",
                            {
                              attrs: {
                                header: _vm.$t("project.display.title"),
                              },
                            },
                            [
                              _vm._l(_vm.display, function (item, key) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: key,
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleVisible(key)
                                            },
                                          },
                                          model: {
                                            value: _vm.display[key],
                                            callback: function ($$v) {
                                              _vm.$set(_vm.display, key, $$v)
                                            },
                                            expression: "display[key]",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.displayLabels[key]))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_DISPLAY_SETTINGS_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("kanban.settings.display")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "kanban-container",
          style: _vm.isWidget
            ? `height: ${_vm.height}px`
            : `height: calc(100vh - ${_vm.heightOffset}px)`,
        },
        [
          _vm.loading
            ? _c("span", { staticClass: "kanban-overlay" }, [
                _c("div", {
                  staticClass: "mr-1 spinner-grow spinner-grow-sm text-dark",
                }),
                _vm._v(_vm._s(_vm.$t("kanban.loading"))),
              ])
            : _c(
                "div",
                {
                  staticClass: "kanban-board",
                  class: { mobile: _vm.mobile },
                  style: `min-height: calc(100vh - ${
                    _vm.heightOffset + 100
                  }px)`,
                  attrs: { id: "kanban-board", set: (_vm.move = []) },
                },
                [
                  _vm.configDidLoad && _vm.columns.length == 0
                    ? _c("div", { attrs: { id: "kanban-empty" } }, [
                        _vm._v(" No project stages defined. "),
                      ])
                    : _vm._e(),
                  _vm.configDidLoad &&
                  _vm.columns.length > 0 &&
                  _vm.enabledColumns.length == 0
                    ? _c("div", { attrs: { id: "kanban-empty" } }, [
                        _vm._v(" No project stages are displayed. "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.enabledColumns, function (column, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "kanban-column",
                        class: { "max-reached": _vm.columnHasMax(column) },
                        attrs: { "data-id": column.uuId },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kanban-column-title",
                            style: {
                              backgroundColor: column.color,
                              visibility:
                                column.dropType == "archive"
                                  ? "hidden"
                                  : "visible",
                            },
                          },
                          [
                            _c("span", { staticClass: "kanban-column-name" }, [
                              _vm._v(_vm._s(column.name)),
                            ]),
                            _c(
                              "span",
                              { staticClass: "kanban-column-title-right" },
                              [
                                (!_vm.dataview || _vm.canList("PROJECT")) &&
                                _vm.canAdd("TASK")
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "kanban-column-add",
                                        attrs: { id: `BTN_ADD_${index}` },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onNewTask(
                                              column.uuId,
                                              column.progress,
                                              _vm.projectId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "far fa-circle-plus" },
                                          [
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target: `BTN_ADD_${index}`,
                                                  triggers: "hover",
                                                  placement: "top",
                                                  boundary: "viewport",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("button.add_task")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  { staticClass: "kanban-column-limit" },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          column.total
                                            ? column.total
                                            : column.tasks.length
                                        ) +
                                        _vm._s(
                                          column.limit ? "/" + column.limit : ""
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "kanban-column-card-container dropzone",
                            class: {
                              "dropzone-stage": column.dropType == "stage",
                              "dropzone-backlog": column.dropType == "backlog",
                            },
                            on: {
                              dragenter: function ($event) {
                                return _vm.dragEnter($event)
                              },
                              dragleave: function ($event) {
                                return _vm.dragLeave($event)
                              },
                              dragover: _vm.allowDrop,
                              drop: function ($event) {
                                return _vm.drop($event)
                              },
                            },
                          },
                          [
                            column.dropType == "archive"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "archive-content-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropzone dropzone-archive",
                                        },
                                        [
                                          _vm._m(0, true),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("kanban.archive_task")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm._l(column.tasks, function (task, tIndex) {
                              return _c(
                                "div",
                                {
                                  key: `${tIndex}_${task.uuId}_${JSON.stringify(
                                    _vm.coloring
                                  )}_${task.loaded}`,
                                  staticClass: "kanban-column-card",
                                  class: {
                                    highlight: task.uuId == _vm.highlightOnLoad,
                                    nograb: !_vm.canView("TASK"),
                                  },
                                  style:
                                    (_vm.dragId == task.uuId &&
                                      _vm.highlightColor) ||
                                    _vm.getStyle(task, true),
                                  attrs: {
                                    "data-id": task.uuId,
                                    "data-index": index,
                                    "data-tIndex": tIndex,
                                    draggable: _vm.canEdit("TASK")
                                      ? true
                                      : false,
                                    set: (_vm.move[task.uuId] = _vm.getMoveMap(
                                      column.uuId,
                                      task.projectId
                                        ? task.projectId
                                        : _vm.projectId
                                    )),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.canView("TASK")
                                        ? _vm.onEdit(task)
                                        : ""
                                    },
                                    dragstart: function ($event) {
                                      return _vm.dragStart($event)
                                    },
                                    dragend: function ($event) {
                                      return _vm.dragEnd($event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "lazy-component",
                                    {
                                      attrs: {
                                        "wrapper-tag": "section",
                                        threshold: [0, 0.25, 0.5, 0.75, 1],
                                        "data-uuId": task.uuId,
                                      },
                                      on: {
                                        intersected: function ($event) {
                                          return _vm.onIntersected(
                                            column.tasks,
                                            tIndex,
                                            ...arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.display.image
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "card-line-img",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                            },
                                            [
                                              _vm.display.image &&
                                              task.avatarRef != null
                                                ? _c("b-img-lazy", {
                                                    staticClass:
                                                      "card-image mb-1",
                                                    staticStyle: {
                                                      "object-fit": "contain",
                                                    },
                                                    attrs: {
                                                      src: _vm.avatarUrl(
                                                        task.avatarRef
                                                      ),
                                                    },
                                                    nativeOn: {
                                                      error: function ($event) {
                                                        return _vm.handleImageError(
                                                          task.avatarRef
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.projectId && _vm.display.project_name
                                        ? _c(
                                            "div",
                                            { staticClass: "card-line0" },
                                            [
                                              _c(
                                                "v-clamp",
                                                {
                                                  staticClass:
                                                    "kanban-card-project",
                                                  style:
                                                    (_vm.dragId == task.uuId &&
                                                      _vm.highlightColor) ||
                                                    _vm.getStyle(task),
                                                  attrs: {
                                                    autoresize: "",
                                                    "max-lines": 1,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.projects[
                                                          task.projectId
                                                        ].name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "card-line0" },
                                        [
                                          _c(
                                            "v-clamp",
                                            {
                                              staticClass: "kanban-card-path",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                              attrs: {
                                                autoresize: "",
                                                "max-lines": 1,
                                              },
                                            },
                                            [_vm._v(_vm._s(task.path))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "card-line1" },
                                        [
                                          _c(
                                            "v-clamp",
                                            {
                                              staticClass: "kanban-card-title",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                              attrs: {
                                                autoresize: "",
                                                "max-lines": 2,
                                              },
                                            },
                                            [_vm._v(_vm._s(task.name))]
                                          ),
                                          _vm.display.avatar
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kanban-card-users",
                                                },
                                                _vm._l(
                                                  _vm.staffsForCard(
                                                    task.staffs
                                                  ),
                                                  function (staff) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        key: staff.uuId,
                                                        staticClass:
                                                          "user-holder",
                                                      },
                                                      [
                                                        staff.uuId in
                                                        _vm.avatars
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "kanban-owner-label picture",
                                                                    style: {
                                                                      backgroundImage:
                                                                        "url(" +
                                                                        _vm.urlForStaffAvatar(
                                                                          staff.uuId
                                                                        ) +
                                                                        ")",
                                                                    },
                                                                    attrs: {
                                                                      id: `STAFF_AVATAR_${task.uuId}_${staff.uuId}`,
                                                                    },
                                                                  },
                                                                  [_vm._v(" ")]
                                                                ),
                                                                _c(
                                                                  "b-popover",
                                                                  {
                                                                    attrs: {
                                                                      target: `STAFF_AVATAR_${task.uuId}_${staff.uuId}`,
                                                                      triggers:
                                                                        "hover",
                                                                      placement:
                                                                        "top",
                                                                      boundary:
                                                                        "viewport",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          staff.fullname
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "kanban-owner-label letter",
                                                                    class: {
                                                                      small:
                                                                        staff.small,
                                                                    },
                                                                    attrs: {
                                                                      id: `STAFF_AVATAR_${task.uuId}_${staff.uuId}`,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          staff.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-popover",
                                                                  {
                                                                    attrs: {
                                                                      target: `STAFF_AVATAR_${task.uuId}_${staff.uuId}`,
                                                                      triggers:
                                                                        "hover",
                                                                      placement:
                                                                        "top",
                                                                      boundary:
                                                                        "viewport",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          staff.fullname
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.display.description
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "card-line2",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                            },
                                            [
                                              _vm.display.description
                                                ? _c(
                                                    "v-clamp",
                                                    {
                                                      staticClass:
                                                        "kanban-card-description",
                                                      attrs: {
                                                        autoresize: "",
                                                        "max-lines": 3,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(task.description)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.display.due ||
                                      _vm.display.priority ||
                                      _vm.display.progress
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "card-line3",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                            },
                                            [
                                              _vm.display.due &&
                                              _vm.canView("TASK", ["closeTime"])
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kanban-card-due",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "far fa-calendar-days",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            task.closeTime
                                                              ? _vm.shortDate(
                                                                  task.closeTime
                                                                )
                                                              : ""
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.display.priority &&
                                              _vm.canView("TASK", ["priority"])
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kanban-card-priority",
                                                      class: task.priority,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(task.priority)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.display.progress &&
                                              _vm.canView("TASK", ["progress"])
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kanban-card-progress",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          task.progress
                                                            ? Math.round(
                                                                task.progress *
                                                                  100
                                                              )
                                                            : 0
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.canEdit("TASK")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-line4 quick-movers",
                                              style:
                                                (_vm.dragId == task.uuId &&
                                                  _vm.highlightColor) ||
                                                _vm.getStyle(task),
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _vm.move[task.uuId].leftId
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              id: `kanban_settings_move_left-${tIndex}-${
                                                                _vm.move[
                                                                  task.uuId
                                                                ].leftId
                                                              }`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.moveByArrow(
                                                                  task.uuId,
                                                                  _vm.move[
                                                                    task.uuId
                                                                  ].leftId,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-circle-chevron-left",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target: `kanban_settings_move_left-${tIndex}-${
                                                                _vm.move[
                                                                  task.uuId
                                                                ].leftId
                                                              }`,
                                                              triggers: "hover",
                                                              placement: "top",
                                                              boundary:
                                                                "viewport",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "kanban.move_to"
                                                                  ) +
                                                                    _vm.move[
                                                                      task.uuId
                                                                    ].leftName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "span",
                                                [
                                                  _vm.move[task.uuId].rightId
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              id: `kanban_settings_move_right-${tIndex}-${
                                                                _vm.move[
                                                                  task.uuId
                                                                ].rightId
                                                              }`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.moveByArrow(
                                                                  task.uuId,
                                                                  _vm.move[
                                                                    task.uuId
                                                                  ].rightId,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-circle-chevron-right",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target: `kanban_settings_move_right-${tIndex}-${
                                                                _vm.move[
                                                                  task.uuId
                                                                ].rightId
                                                              }`,
                                                              triggers: "hover",
                                                              placement: "top",
                                                              boundary:
                                                                "viewport",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "kanban.move_to"
                                                                  ) +
                                                                    _vm.move[
                                                                      task.uuId
                                                                    ].rightName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
        ]
      ),
      _c("TaskModal", {
        attrs: {
          show: _vm.taskEditShow,
          id: _vm.taskEdit.uuId,
          projectId: _vm.taskEdit.projectId,
          parentId: _vm.taskEdit.parentId,
          stageForNew: _vm.taskEdit.stageId,
          progressForNew: _vm.taskEdit.progress,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.taskEditSuccess,
        },
      }),
      _c("KanbanSettingsModal", {
        attrs: {
          columns: _vm.columns,
          profile: _vm.profile,
          projects: _vm.projects,
          show: _vm.kanbanSettingsShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.kanbanSettingsShow = $event
          },
          success: _vm.saveSettings,
        },
      }),
      _vm.projectSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.projectSelectorShow,
              entityService: _vm.projectUtil,
              entity: "PROJECT",
              projectIds: _vm.selectorProjectIds,
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.projectSelectorShow = $event
              },
              ok: _vm.projectSelectorOk,
            },
          })
        : _vm._e(),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("ProjectFilterModal", {
        attrs: {
          show: _vm.filterShow,
          title: _vm.$t("kanban.filter"),
          userId: _vm.userId,
          entity: ["TASK"],
          data: _vm.filter,
        },
        on: {
          "update:show": function ($event) {
            _vm.filterShow = $event
          },
          success: _vm.filterSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "far fa-sign-out-alt" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }