export default {
  defaultColors: [
    "#2ed3c0",
    "#3abeff",
    "#5a9b9e",
    "#ed6b86",
    "#7fb285",
    "#0f4c5c",
    "#e36414",
    "#fb8b24",
    "#9a031e",
    "#5f0f40"
  ],
  sortTypes: [
    // Make key same as field name -- we use it to check which field was
    // edited to know if we need to sort again
    {"key": 'path',      "label": "Task Path",   "sort" : "PROJECT.TASK.path"},
    {"key": 'name',      "label": "Task Name",   "sort" : "PROJECT.TASK.name"},
    {"key": 'startTime', "label": "Start Date",  "sort" : "PROJECT.TASK.startTime"},
    {"key": 'closeTime', "label": "Finish Date", "sort" : "PROJECT.TASK.closeTime"},
    {"key": 'progress',  "label": "Progress",    "sort" : "PROJECT.TASK.progress"},
  ],
  getDefaultColor(idx) {
    // If we have more than the default color set, start from the beginning again
    while (idx >= this.defaultColors.length) {
      idx -= this.defaultColors.length;
    }
    return this.defaultColors[idx];
  },
  getSortForKey(key) {
    for (var i = 0; i < this.sortTypes.length; i++) {
      if (this.sortTypes[i].key == key) {
        return this.sortTypes[i]['sort'];
      }
    }
  }
}